<template>
  <div class="main-content">
    <div class="header bg-white border-bottom">
      <div class="container-fluid">
        <div class="header-body border-0">
          <div class="row align-items-end">
            <div class="col">
              <h6 class="header-pretitle">Overview</h6>
              <h1 class="header-title">Suggested Routes</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header justify-content-between">
              <div
                class="input-group input-group-flush d-flex flex-row-reverse"
                >
                <input
                  v-model.trim="search"
                  class="form-control list-search"
                  type="search"
                  placeholder="Search"
                  />
                <span class="input-group-text border-0">
                  <i class="fe fe-search"></i>
                </span>
              </div>
              <div class="col-auto">
                <div class="flex items-center gap-x-6">
                  <v-datepicker
                  v-model="range"
                  placeholder="Filter by date"
                  range
                  ></v-datepicker>
                  <div class="">
                    <download-button class="cursor-pointer" v-if="filteredRoutes.length" :downloading="downloadingReport"
                      label="Download Report" @download="downloadReport()"></download-button>
                  </div>
                </div>
              </div>
            </div>
            <b-table
              striped
              hover
              selectable
              responsive
              :items="filteredRoutes"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              :busy="fetchingRoutes"
              >
              <template #table-busy>
                <div class="text-center text-secondary my-2">
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(user)="data">
                <span class="text-sm">{{ data.item.name }}</span><br />
                <span class="text-sm">{{ data.item.email.replace('Email: ', '') }}</span><br />
                <span class="text-sm">{{ data.item.phone.replace('Phone: ', '') }}</span>
              </template>
              <template #cell(created_at)="data">
                {{ data.value | date('dd/MM/yyyy') }}
              </template>
              <template #cell(from)="data">
                {{ data.item.pickup }}
              </template>
              <template #cell(to)="data">
                {{ data.item.destination }}
              </template>
              <template #cell(departure_time)="data">
                {{ data.item.departure_time }} {{ data.item.time_of_day }}
              </template>
            </b-table>

            <div class="card-footer" v-if="filteredRoutes.length">
              <b-pagination
                v-model="currentPage"
                :total-rows="filteredRoutes.length"
                :per-page="perPage"
                ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { ExportToCsv } from 'export-to-csv'
import DownloadButton from '@/components/core/DownloadButton.vue'
export default {
  components: {
    DownloadButton
  },
  data () {
    return {
      fields: [
        {
          key: 'created_at',
          label: 'Date'
        },
        {
          key: 'user',
          label: 'User'
        },
        {
          key: 'departure_time',
          label: 'Departure Time'
        },
        {
          key: 'from',
          label: 'From'
        },
        {
          key: 'to',
          label: 'To'
        }
      ],
      perPage: 20,
      currentPage: 1,
      routes: [],
      fetchingRoutes: true,
      downloadingReport: false,
      search: '',
      range: []
    }
  },
  created () {
    this.fetchRoutes()
  },
  computed: {
    filteredRoutes () {
      const search = this?.search?.toLowerCase()
       let routes = null
       routes = this.routes.filter((route) => {
        return (
          route?.pickup?.toLowerCase().includes(search) ||
          route?.destination?.toLowerCase().includes(search) ||
          route?.name?.toLowerCase().includes(search)
        )
      })

      if (this.dateFilterValid) {
        routes = this.routes.filter(
          (route) =>
            route?.created_at.split(' ')[0] >= this.dateRange[0] &&
            route?.created_at.split(' ')[0] <= this.dateRange[1]
        )
      }

      return routes
    },

    dateFilterValid () {
      return this.range.length && this.range.filter((date) => !!date).length > 0
    },
    dateRange () {
      return this.range.map((date) => moment(date).format('YYYY-MM-DD'))
    }
  },
  methods: {
    fetchRoutes () {
      this.fetchingRoutes = true

      this.axios
        .get('/v1/suggest')
        .then((res) => {
          this.routes = res?.data
        })
        .finally(() => (this.fetchingRoutes = false))
    },
   async downloadReport (){
      try {
        this.$Progress.start()
        this.downloadingReport = true
        const results = await this.axios.get('/v1/suggest')
        const routes = results.data
        this.$Progress.finish()
        const csvData = routes.map((data) => {
          return {
            name: data?.name,
            email: data?.email?.replace('Email: ', ''),
            phone: data?.phone?.replace('Phone: ', ''),
            date: data?.created_at,
            from: data?.pickup,
            to: data?.destination,
            departure_time: `${data?.departure_time} ${data?.time_of_day}`
          }
        })

        const csvParams = {
          fieldSeparator: ',',
          filename: ' Suggested Routes List',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title: 'Suggested Routes List',
          useTextFile: false,
          useBom: true,
          headers: [
            'Name',
            'Email',
            'Phone',
            'Date',
            'From',
            'To',
            'Depature Time'
          ]
        }

        const csvExporter = new ExportToCsv(csvParams)

        csvExporter.generateCsv(csvData)

        this.$swal({
          icon: 'success',
          title: 'Report downloaded',
          text: 'Report has been downloaded successfully',
          showCloseButton: true
        })
      } catch (error) {
        this.$Progress.fail()
      } finally {
        this.downloadingReport = false
      }
    }
  }
}
</script>
